import React from "react"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"

import Banner from "../components/sections/banner"
import SectionWrapper from "../components/sections/section-wrapper"
import Footer from "../components/sections/footer"
import SpeakerExtended from "../components/sections/speaker-expanded"

import useKeynoteSpeakers from "../hooks/useKeynoteSpeakers"

const Keynote = () => {
  const keynoteImages = useKeynoteSpeakers()
  const keynoteSpeakers = [
    {
      id : "mingzhang",
      img : keynoteImages.zhang.childImageSharp.fluid,
      name : "Dr. Ming Zhang",
      description : "Peking University, China",
      bionote : [
        "Ming Zhang received her Bachelor, master and PhD degrees in Computer Science from Peking University respectively. She is a full professor at the Department of Computer Science, Peking University. Prof. Zhang is a member of Advisory Committee of Ministry of Education in China, a member of ACM Education Advisory Committee (formerly Ed Council) and the Chair of ACM SIGCSE China. She is a member of the ACM/IEEE CC2020 steering committee.",
        "She has published more than 200 research papers on Text Mining and Machine Learning in the top journals and conferences, such as ICML, KDD, WWW, ACL, AAAI, IJCAI and TKDE. She won the best paper of ICML 2014 and best paper nominee of WWW 2016. Prof. Zhang is awarded AI2000 most influential Scholar Award in recognition of outstanding and vibrant contributions in the field of Information Retrieval and Recommendation between 2009 and 2019.",
        "Prof. Zhang is the leading author of several textbooks on Data Structures and Algorithms in Chinese, and the corresponding course is awarded as the National Elaborate Course, National Boutique Resource Sharing Course and National Fine-designed Online Course, national first class undergraduate flipped classroom course by MOE China.",
      ],
      talkTitle: "Graph Representation Learning for Drug Discovery",
      talkDescription: "For decades, scientists have been seeking to aid drug discovery with modern computing techniques. Many fundamental problems of drug discovery are closely related to graph representation learning and graph neural networks. Actually, a molecule is naturally a heterogeneous graph, where atoms correspond to nodes and chemical bonds corresponds to edges. Based on this observation, how to use deep learning algorithms, especially graph-based algorithms, to assist various tasks in drug discovery has been attracting growing attention. In this talk, we study two different tasks in drug discovery, leveraging the recent advance in representation learning for molecular graphs. First, we propose a flow-based generative model for molecular graph generation. The training process of our model is two times faster than existing state-of-the-art approach GCPN. After fine-tuning the model for goal-directed generation using reinforcement learning, our model achieves state-of-the-art performance on both property optimization and constrained optimization. Then, we design a template-free approach for retrosynthesis prediction, which bypasses reaction templates and graph isomorphism. Experimental results show that our model significantly outperforms existing template-free approaches and achieves a performance close to that of state-of-the-art template-based approaches but does not require domain knowledge and is much more scalable. The two models are published in ICLR2020 and ICML2020 respectively."
    },
    {
      id : "freisangil",
      img : keynoteImages.sangil.childImageSharp.fluid,
      name : "Frei Sangil",
      description : "Founder/CEO LAYERTech Software Labs (Inc.)",
      bionote : [
        "Frei is the co-founder and current president of Layertech Software Labs, Inc., and is a staunch advocate for inclusive digital transformation. She has led a number of R&D and tech deployment projects with both local and international partners, many of which received recognitions on its impact and efficacy.",
        "In 2015 she received the gold award at Global Innovation Competition by MAVC in Jakarta, Indonesia. In 2016 she received an iCount award from UNAIDS and The PACT for gamified AIDS mapping in youth. In 2017, her system received best global climate practice award given by International Center for Climate Governance (Italy), and in 2018, her team was awarded European Union Civic Tech for Democracy award in Brussels, Belgium— among others. Frei works as cybersecurity, data science and analytics consultant for various companies, governments, and non-government organizations.",
      ],
      talkTitle: "Humans vs Automation: Designing and Implementing Automated Mechanisms with (un)Predictable Humans",
      talkDescription: "For years, Layertech Labs has been working with stakeholders from the government, academe, private sector and civil society organizations on data and ICT-centered solutions deployed on the ground. A very important but sometimes overlooked factor in designing and using ICT-centered solutions is getting the end users-- the \"human\" component, to work effectively with the system. Humans lie, humans don't cooperate, but should we really be blaming them? Frei Sangil will discuss firsthand lessons, challenges, and how data collection and ICT systems can work effectively with (un)predictable human behavior, to get impactful and effective data and ICT solutions."
    },
    {
      id : "novaahmed",
      img : keynoteImages.ahmed.childImageSharp.fluid,
      name : "Dr. Nova Ahmed",
      description : "North South University",
      bionote : [
        "Dr. Nova Ahmed received her bachelor’s degree from the University of Dhaka in Computer Science. She has served as a faculty member in the University of Dhaka right after her graduation. She pursued her MS at Georgia State University and doctoral degree from Georgia Institute of Technology. She served in Georgia Tech Research Institute (GTRI) for a year. She joined North South University since she came back to Bangladesh to serve her country. She enjoys playing with her daughters Anuva and Arisha in her leisure time!",
      ],
      talkTitle: "Thinking of an Equal World",
      talkDescription: "How we can ensure equality for women in computing? We want to ensure it through addressing the social barriers and structural barriers that are out there. Awareness and collaborative approach to solve the problem can take us much further. Nova has discussed it sharing her personal journey through various anecdotes."
    },
  ]
  return (
    <Layout>
      <Navigation />
      <Banner title="Keynote Speakers" />
      <SectionWrapper>
        <div className="flex flex-wrap items-stretch mt-2 md:mt-4">
          {keynoteSpeakers.map((speaker) => (
            <SpeakerExtended
              id={speaker.id}
              width="w-full"
              img={speaker.img}
              name={speaker.name}
              description={speaker.description}
              bionote={speaker.bionote}
              talkTitle={speaker.talkTitle}
              talkDescription={speaker.talkDescription}
            />
          ))}
        </div>
      </SectionWrapper>
      <Footer />
    </Layout>
  )
}

export default Keynote
