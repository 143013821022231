import React from "react"
import avatar from "../../images/avatar.jpg"
import Img from "gatsby-image"

const SpeakerExtended = ({
  id,
  name,
  description,
  bionote,
  talkTitle,
  talkDescription,
  width,
  img,
  spacing,
}) => {
  return (
    <div id={id} className={`${width || "w-1/4"} ${spacing} pb-4`}>
      <div className="flex flex-wrap h-full items-center shadow hover:shadow-md transition-all ease-in bg-white rounded">
        <div className="w-full lg:w-4/12 bg-white lg:h-full relative border">
          <div className="sticky top-7">
            {img == null && (
              <img className="w-full m-6" src={avatar} alt="avatar" />
            )}
            {img != null && (
              <Img
                className="h-80 md:h-96 lg:h-screen-95 w-full"
                fluid={img}
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            )}
          </div>
        </div>
        <div className="w-full lg:w-8/12 p-6">
          <p className="text-sm font-bold text-accent">{description}</p>
          <h2 className="font-semibold text-primary text-xl">{name}</h2>
          {bionote != null &&
            bionote.map((paragraph) => <p className="mt-2">{paragraph}</p>)}
          <p className="text-sm font-bold text-accent mt-8">Title</p>
          <h2 className="font-semibold text-primary text-xl">{talkTitle}</h2>
          <p className="mt-2">{talkDescription}</p>
        </div>
      </div>
    </div>
  )
}

export default SpeakerExtended
