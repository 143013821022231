import { useStaticQuery, graphql } from "gatsby"

const useKeynoteSpeakers = () => {
  const keynoteSpeakers = useStaticQuery(graphql`
    query {
      sangil: file(relativePath: { eq: "frei_sangil.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zhang: file(relativePath: { eq: "mzhang.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ahmed: file(relativePath: { eq: "novaahmed.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return keynoteSpeakers
}

export default useKeynoteSpeakers
